import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Spinner, VStack, Heading, Text } from '@chakra-ui/react'
import { useEventContext } from '../../../contexts/EventProvider'
// import { logPurchasedEvent } from '@vidday/utils/dist/trackingEventActions'
import { Helmet } from 'react-helmet'
import CheckExMark from '../../atoms/CheckExMark'
import Confetti from '../../molecules/Confetti'
import { useAuthContext } from '../../../contexts/AuthProvider'
import Card from '../../atoms/Card'
import { isBrowser } from 'react-device-detect'
import useDataLayerPush from '../../../hooks/useDataLayerPush'

const Paid = () => {
	/** Retrieve current event global state */
	const {
		event: { status, uuid, occasion },
	} = useEventContext()
	/** Retrieve the history hook */
	const navigate = useNavigate()
	const location = useLocation()
	const [title, setTitle] = useState('')
	const { id, email, profile } = useAuthContext()
	const [checkoutSession, setCheckoutSession] = useState(null)

	// leftovers from the old tracking
	// useEffect(() => {
	// 	const mqStandAlone = '(display-mode: standalone)'
	// 	const appInstalled = navigator.standalone || window.matchMedia(mqStandAlone).matches ? true : false
	// })

	useEffect(() => {
		if (isBrowser) {
			const search = new URLSearchParams(location.search)
			if (search.has('session_id')) {
				// fetch checkout session details
				fetch(`/api/stripe/retrieve-session?session_id=${search.get('session_id')}`)
					.then((res) => res.json())
					.then((data) => {
						setCheckoutSession(data)
						// strip `session_id` from url search params
						navigate(location.pathname, { state: { isActive: true } })
					})
					.catch((err) => console.error(err))
			}
		}
	}, [])

	useEffect(() => {
		if (isBrowser && checkoutSession && checkoutSession.status == 'complete') {
			// format amounts
			const value = Number(
				(
					(checkoutSession.amount_total -
						checkoutSession.total_details.amount_shipping -
						checkoutSession.total_details.amount_tax) /
					100
				).toFixed(2)
			)
			const tax = Number((checkoutSession.total_details.amount_tax / 100).toFixed(2))
			const shipping = Number((checkoutSession.total_details.amount_shipping / 100).toFixed(2))

			// fire "purchase" GTM event
			useDataLayerPush({ ecommerce: null }) // IMPORTANT - clear out previous ecommerce info
			useDataLayerPush({
				event: 'purchase',
				ecommerce: {
					transaction_id: checkoutSession.id,
					value: value, // the total excluding shipping & tax
					tax: tax,
					shipping: shipping,
					currency: checkoutSession.currency.toUpperCase(),
					coupon: checkoutSession.metadata?.promoCode?.length > 0 ? checkoutSession.metadata.promoCode : null,
					items: checkoutSession.line_items.data.map((item, index) => {
						// format unitary amounts
						const discount = Number((item.amount_discount / item.quantity / 100).toFixed(2))
						const price = Number((item.amount_total / item.quantity / 100).toFixed(2))

						return {
							index: index,
							item_id: item.price.product,
							coupon: null,
							discount: discount,
							price: price,
							quantity: item.quantity,
						}
					}),
				},
			})
		}
	}, [checkoutSession])

	// Escape route - A fallback should the socket fail reload the event/page
	useEffect(() => {
		// if event is already in publishing, just wait a timeout before redirecting
		if (status === 'publishing') {
			setTitle("VidDay - Payment Successful! We'll begin publishing.")
			setTimeout(() => {
				navigate(`/event/${uuid}`, { state: { isActive: true } })

				// history.replace({ pathname: `/${uuid}`, search: '', state: { isActive: true } })
			}, 3000) // enough time to let confetti fall a bit
		} else {
			setTitle('VidDay - finalizing video...')
		}
	}, [status])

	const Success = () => {
		return (
			<VStack alignItems="center" justifyContent="center" w="full" minH="300px">
				{occasion != 'memorial' && <Confetti fire={true} />}
				<CheckExMark render={true} check={true} scale={0.6} />
				<Heading color="gray.900" fontSize="26px">
					Your video is finalizing...
				</Heading>
			</VStack>
		)
	}
	const Waiting = () => {
		return (
			<VStack alignItems="center" justifyContent="center" w="full" minH="300px">
				<Spinner thickness="6px" speed="0.45s" emptyColor="gray.200" color="link" size="lg" />
				<Heading color="gray.900" fontSize="26px">
					Finalizing your video...
				</Heading>
			</VStack>
		)
	}

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="robots" content="noindex nofollow noarchive" />
			</Helmet>
			{location.search === '' && (
				<Container py="2rem">
					<Card p="2rem" width="full">
						{status == 'publishing' && <Success />}
						{status == 'active' && <Waiting />}
					</Card>
				</Container>
			)}
		</>
	)
}

export default Paid
